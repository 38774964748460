<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ตั้งค่าผู้ใช้งาน</div>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        small
        class="ml-5"
        @click="(user_id = null), (dialogUpdate = true)"
        ><v-icon left small>mdi-plus</v-icon> เพิ่มผู้ใช้งาน</v-btn
      >
    </v-toolbar>

    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
      @click:row="edit"
    >
      <!-- <template v-slot:[`item.status`]="{ item }">
        <menu-ship small v-model="item.status"/>
      </template> -->

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>

    <v-dialog persistent scrollable max-width="1200" v-model="dialogUpdate">
      <Detail
        :id="user_id"
        @onClose="dialogUpdate = false"
        @onSave="
          () => {
            dialogUpdate = false;
            this.user_id = 0;
            getData();
          }
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import Detail from '@/views/pages/setting/user/Detail.vue';
import * as moment from 'moment';

export default {
  components: {
    Detail
  },
  data: () => ({
    loading: true,
    pageCount: 0,
    headers: [
      { text: 'ชื่อ', value: 'name' },
      { text: 'Username', value: 'username' },
      { text: 'ชื่อสาขา', value: 'permission.branchs' },
      { text: 'กลุ่มผู้ใช้', value: 'permission.user_group' },
      { text: '', value: 'actions', sortable: false }
    ],
    list: [],
    dialogUpdate: false,
    filter: {
      item_per_page: 20,
      page: 1,
      start_with: ''
    },
    user_id: 0
  }),

  created() {
    this.getData();
  },

  methods: {
    add() {
      this.$router.push({ name: 'setting-user-create' });
    },
    edit(item) {
      console.log(item.username);
      this.user_id = item._id;
      this.dialogUpdate = true;
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign({ user_id: item._id }, this.$privateKey, {
                noTimestamp: true
              })
            };

            await this.$axios
              .post(`${this.$baseUrl}/users/delete_user`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
            console.log('remove()');
          }
        }
      );
    },
    async getData() {
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/users/get_user`, body)
        .then(res => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    }
  }
};
</script>
